.main {
    background: linear-gradient(103deg,#575150,#201b1b,#575150);
    min-height: 230px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    padding-left: 20px;
}
.tit {
    font-size: 16px;
    color: white;
}
.maintitle {
    color: white;
    font-size: 20px;
}
.ylw {
    color: #ebba60;
    padding: 0 10px;
}
.white {
    color: white;
}

.mv_title {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    line-height: 35px;
    padding-left: 5px;
}
.mv_nc {
    color: #fff;
    display: grid;
    gap: 10px;
    padding-left: 10px;
}
.mv_in {
    color: #ebba60;
    padding: 0 10px;
}