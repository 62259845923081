.selected {
    border-radius: 50%;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    width: 40px;
    height: 40px;
    border: solid;
    background: #e77996;
    color: white;
    cursor: pointer;
}

.un {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
