.img_con {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
    margin-bottom: 20px;
}
.ts_con {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
    margin-bottom: 20px;
}
.xs_con {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #fff;
}
.ys_con  {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.ys_con > div > div > div {
    display: flex;
    align-items: center;
    gap: 20px;
}

.xs_con > div {
    padding: 10px;

}
.xs_con > div:hover {
    padding: 10px;

}
.xs_con > div > div > div {
    display: grid;
    gap: 10px;
    padding: 10px;
}
.xs_con>div:hover {
    color: #e77996 !important;
    cursor: pointer;
}
.xs_title {
    font-size: large;
    font-weight: 700;
}
.xs_t {
    font-size: 12px;
    font-weight: 400;
}
.xs_d {
    /* color: #6A6A6A; */
    font-size: 16px;
    line-height: 30px;
    height: 64px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.xs_b {
    /* color: #333333; */
    font-size: 12px;
}

.ys_t {
    color: #000;
    font-size: 18px;
    font-weight: 700;
}
.ys_g {
    color: #666;
    font-size: 13px;
    margin: 10px 0;
}
.ys_sm {
    color: #e797a5;
    font-size: 13px;
}
.ys_b {
    color: #888;
    font-size: 12px;
}
.ys_main {
    cursor: pointer;
}
.yw_main {
    display: grid;
    gap: 10px;
}

.list_item {
    display: grid;
    grid-template-rows: 1fr 40px;
    /* grid-template-rows: 1fr 20px; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
}
.xs_list_item {
    display: grid;
    grid-template-rows: 1fr 1fr;
}
.list_item>div:nth-child(2) {
    color: #333;
    font-size: 14px;
    /* font-weight: 600; */
}
.shadow {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(transparent,rgba(0,0,0,.5));
    color: #fff;
    filter: alpha(opacity=0);
    cursor: pointer;
    transition: opacity .3s;
}
.shadow>*:nth-child(1) {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -55%);
    top: 50%;
}
.shadow>*:nth-child(2) {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: right;
    background-image: linear-gradient(transparent,rgba(0,0,0,.5));
}
.shadow>*:nth-child(2)>div:nth-child(1) {
    padding-right: 5px;
    padding-bottom: 5px;
}
.vipmark {
    background: #dab572;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 700;
    padding: 3px 5px;
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 99;
}
.vipmark:hover {
    color: #FA528E;
}
.vid_duration {
    position: absolute;
    bottom: 3px;
    padding: 2px 5px;
    left: 2px;
    color: white;
    background: rgb(31 41 55 / .75);
    width: max-content;
    float: left;
    text-align: left;
    border-radius: 4px;
    font-size: 13px;
}
.vid_time {
    font-size: 12px;
    position: absolute;
    bottom: 0px;
    color: white;
    background-image: linear-gradient(transparent,rgba(0,0,0,.5));
    width: 100%;
    float: right;
    text-align: right;
}
.vid_time>div:nth-child(1) {
    padding-right: 5px;
    padding-bottom: 4px;
    font-weight: 500;
}
.tag {
    position: absolute;
    top: 5px;
    left: 5px;
    /* bottom: 5px; */
    background: #FA528E;
    padding: 2px 10px;
    border-radius: 50px;
    color: white;
    font-size: 10px;
    font-weight: 700;
    z-index: 50;
}

@media only screen and (max-width: 600px) {
    .img_con {
        grid-template-columns: repeat(2, 1fr);
        padding-left: 10px;
        padding-right: 10px;
    }
    .ts_con {
        grid-template-columns: repeat(2, 1fr);
        padding-left: 10px;
        padding-right: 10px;
    }
    .img_con img{
        object-fit: contain;
    }
    .xs_con {
        display: grid;
        grid-template-columns: 1fr;
        background-color: #fff;
    }
    .ys_con  {
        display: grid;
        grid-template-columns: 1fr;
        padding-left: 10px;
        padding-right: 10px;
    }
}
