.r_l {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 1px 4px rgba(0,0,0,.07);
    padding: 20px 10px;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 10px;
    margin: 10px 10px 0px 10px;
}
.r_l > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}
.t {
    margin-top: 10px;
}