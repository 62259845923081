.title {
    color: #e77996;
    font-size: 18px;
    text-align: center;
}
.inner_title {
    color: #333;
    font-size: 20px;
    text-align: center;
}
.charge_btn {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -2px);
    width: 200px;
}