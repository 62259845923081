.d {
    position: fixed;
    z-index: 1000;
    left: 50%;
    transform: translate(-50%, 0px);
    display: flex;
    align-items: center;
    background-color: #7783ea;
    padding: 10px;
    justify-content: center;
    bottom: 0px;
    cursor: pointer;
    height: 53px;
    max-height: 33px;
}
.d >*:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 5px;
}
.d >*:nth-child(1)>*:nth-child(1) {
    width: 132px;
    height: 23px;
}
.d >*:nth-child(1)>*:nth-child(2) {
    width: 115px;
    height: 36px;
}
.d >*:nth-child(1)>*:nth-child(3) {
    font-size: 18px;
    color: #fff;
    color: white;
}
.close {
    margin-top: 5px;
    padding-left: 5px;
}

@media only screen and (max-width: 600px) {
    .d {
        width: 100%;
    }
    .d >*:nth-child(1)>*:nth-child(1) {
        height: 23px;
    }
    .d >*:nth-child(2)>*:nth-child(2) {
        height: 31px;
    }
    .d >*:nth-child(1)>*:nth-child(3) {
        font-size: 15px !important;
        color: #fff;
        color: white;
    }
}

@media only screen and (max-width: 400px) {
    .d >*:nth-child(1)>*:nth-child(1) {
        width: 105px;
    }
}
