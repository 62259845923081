.content {
    position: absolute;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 1px 2px 3px #333;
}
.redexpire {
    color: red;
    font-weight: 700;
}
.mt110 {
    margin-top: 110px;
}
.btm {
    margin-top: 39px;
    gap: 30px;
}