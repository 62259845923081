.fs {
    position: fixed;
    bottom: 86px;
    height: 200px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    right: 20px;
    z-index: 990;
}

.fs > div {
    width: 40px;
    height: 40px;
    background: #fff;
    border: 1px solid #d8cdcd;
    display: flex;
    align-items: center;
    justify-content: center;
}