
.tabwrapper{
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
}
.tab{
    background: #FF647C;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 10px 0;
    cursor: pointer;
}
.tabactive{
    background: #fff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FF647C;
    border: 1px solid #FF647C;
    padding: 10px 0;
    cursor: pointer;
}
.contentwrapper{
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 40px;
}
.btnwrapper{
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    display: flex;
    margin-top: 40px;
    gap: 10px;
}
.btn{
    width: 50%;
    background: #FF647C;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 10px 0;
    cursor: pointer;
}
.btn_2{
    width: 50%;
    background: #fff;
    border: 1px solid #FF647C;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FF647C;
    padding: 10px 0;
    cursor: pointer;
}
.shuoming{
    display: grid;
    gap: 10px;
}
.topicwrapper{
    margin-top: 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    /* grid-template-columns: repeat(3, minmax(0, 1fr)); */
    display: grid;
    gap: 10px;
}
.topic{
    border: 1px solid #FF647C;
    border-radius: 8px;
    padding: 10px;
    margin: 0 15px;
    cursor: pointer;
}
.topicactive{
    color:  #fff;
    background: #FF647C;
    border-radius: 8px;
    padding: 10px;
    margin: 0 15px;
    cursor: pointer;
}
@media only screen and (max-width: 600px) {
    .topicwrapper{
        margin-top: 10px;
        /* grid-template-columns: repeat(2, minmax(0, 1fr)); */
    }
    .contentwrapper {
        width: 100%;
    }
}