.register {
    grid-template-columns: 1fr 1fr;
}

.regplcholder {
    grid-template-columns: 25% 75%;
}

.form {
    background: #fff;
    border-radius: 3px;
    height: 565px;
    width: 500px;
    font-size: 20px;
    line-height: 80px;
    grid-template-rows: 80px 1fr;
    font-size: 16px;
}
.header {
    background: #e9e8f6;
    text-align: center;
    height: 80px;
}
.t_center {
    text-align: center;
}
.register_inner {
    grid-template-rows: 80px 1fr;
}
.inner2 {
    grid-template-rows: 1fr auto;
    justify-content: center;
    text-align: center;
}
.inner3 {
    grid-template-rows: auto auto auto auto;
    padding-top: 40px;
}
.blue {
    color: #3046ec;
    font-size: 16px;
}
.btm {
    color: #8f8f8f;
    font-size: 16px;
    text-align: center;
}
.click_register {
    color: #3046ec;
    cursor: pointer;
}

@media only screen and (max-width: 750px) {
   .form {
        order: 1;
        width: 100% !important;
   }
   .register {
        grid-template-columns: none;
        grid-template-rows: 200px;
   }
   .inner3 {
    width: 100% !important;
   }
}