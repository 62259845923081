.content {
width: 100%;
}
.pbody {
    box-shadow: none;
    background: none;
    width: 100%;
}
.eggmodal {
    position: relative;
}
.eggmodal img {
    max-width: 80% !important;
}
.eggcontent {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
    bottom: 50px;
    width: 100%;
}
.btn1 {
    background: linear-gradient(180deg, #FE797A 0%, #FF544D 100%);
    border: 1px solid #FE797A;
    color: white;
    border-radius: 25px;
    width: 70%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: auto;
    cursor: pointer;
    max-width: 270px;
}
.btn2 {

    border: 1px solid #FE797A;
    color: #FF544D;
    border-radius: 25px;
    width: 70%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: auto;
    margin-top: 15px;
    cursor: pointer;
    max-width: 270px;
}