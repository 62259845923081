.con {
    background: #fff;
    border-radius: 10px;
    color: #666;
    margin-top: 15px;
    padding: 20px;   
}
.con > div:nth-child(1) {
    color: #333;
    font-size: 18px;
    font-weight: 700;
    line-height: 45px;
}

.con > div:nth-child(2) {
    display: grid;
    gap: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.bggrey {
    background-color: #f1f2f4;
}
.bggrey > input {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    height: 38px;
}
.bggrey > select {
    height: 38px;
}
.bggrey1 {
    width: 147px;

}
.bggrey1 > input {
    text-align: left;
}

.cont {
    display: grid;
    gap: 10px;
}
.cont > div > select {
    height: 38px;
}
.cont > div:nth-child(2) {
    width: 100%;
    border-radius: 6px;
}

.first {
    grid-template-columns: 80px 1fr;
}
.second {
    grid-template-columns: 147px 80px;
    display: grid;
    gap: 10px;
    align-items: center;
}
.second > div:nth-child(1) {
    border-radius: 6px;
}
.second > button:nth-child(2) {
    font-size: 12px;
    width: 100% !important;
    height: 100%;
}
.b_btn > button {
    width: 100%;
}