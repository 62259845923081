.btop {
    margin-top: 80px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
    .btop {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
    }
}