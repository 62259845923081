.m {
    padding: 20px;
}
.title {
    font-size: 28px;
    color: #333;
    text-align: center;
}
.feedback_inner_title {
    color: #666;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
}
.textarea {
    width: 98%;
    font-size: 14px;
    margin-top: 10px;
    /* margin: auto; */
}
.radio {
    width: 13px;
    height: 13px;
}
.text {
    font-size: 13px;
    color: #333;
}