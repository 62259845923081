.title_bg {
    position: relative; /* Ensure the pseudo-element is positioned relative to this element */
    background-image: url("/public/images/bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 300px; /* Use min-height instead of height to allow for more content */
    width: 100%; /* Full width */
  }
  
  @screen lg{
    .title_bg{
      overflow: hidden !important;
    }
    .mw1100 {
        width: 1000px;
        margin: auto;
    }
    .youshi-grid-col {
      grid-template-columns: 8% 92% !important;
    }
  }
  
  .title_bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1); /* Black color with 50% opacity for dark overlay */
    z-index: 1; /* Ensures overlay is above the background image */
  }
  
  .title_bg > * {
    position: relative; /* Ensures content is above the overlay */
    z-index: 2; /* Ensures content is above the overlay */
  }
  
  @screen lg{
    .banner_bg {
      background-image: url('/public/images/banner.png') !important; /* Use the leading slash to access the public folder */
    }
  }
  
  .banner_bg{
    background-image: url('/public/images/banner_mobile.png'); /* Use the leading slash to access the public folder */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .balancegrid {
      grid-template-columns: 20% 20% 20%;
  }
  
  .youshigrid {
      grid-template-columns: 15% 85%;
  }

  .pinkbg {
    background-color: #FA6891;
  }

  .pinktext {
    color: #FA6891;
  }