.ts {
    margin-top: 20px !important;
    grid-template-columns: 1fr;
}
.ts_logo {
    width: 80px;
    height: 80px;
    border-radius: 50px;
}
.ts_title {
    color: #242424;
    font-size: 18px;
    font-weight: 700;
    /* padding: 10px !important; */
    grid-column: span 1 / span 1;
}
.line {
    height: 1px;
    width: 100%;
    background: #E7E7E7;
}
.gif_wrapper{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 10px;
}
.gif_images{
    height: auto;
}
.centered_gif_wrapper{
    background-color: rgba(0, 0, 0, 0.8); /* Adjust the alpha value (0.5) for transparency */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999; /* Place it behind other content */
}
.centered_gif{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}
.centered_gif > img {
    max-width: 600px;
    width: 600px;
    max-height: 600px;
}
.ts_title_top {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px;
    margin-bottom: 10px !important;
}
.ts_container {
    border: 1px solid #ECECEC;
    background-color: white;
    align-items: center;
}
.importanttext{
    color: rgb(255, 52, 125);
    font-weight: normal;
    padding-top: 10px;
}
.desc {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    padding: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
}
.left_cover {
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: end;
    text-align: right;
}
.ml250 {
    margin-left: 250px;
}
.main1 {
    display: none;
}
.ts_detail {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
}
.ts_de_img {
    border-radius: 50px;
}
.ts_sin_vid {
    display: none;
}
.currentprice{
    font-size: 16px;
    font-weight: 700;
}
.becomevip{
    border: 1px solid #FF347D;
    background: #FF347D26;
    color: #FF347D;
    padding: 4px 8px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.pricetag{
    display: grid;
}
.pricetagmobile{
    display: none;
}
.buttontag{
    width: 50%;
}
.gif_title{
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
    font-size: 16px;
}
@media only screen and (max-width: 600px) {
    .centered_gif > img {
        max-height: 350px;
        max-width: 350px;
    }
    .gif_title{
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
    .gif_wrapper{
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
    .ts_title_top{
        gap: 10px !important;
    }
    .pricetagmobile{
        display: flex;
        grid-column: span 2/ span 2;
    }
    .buttontag{
        width: 100% !important;
    }
    .pricetag{
        display: none !important;
    }
    .ts {
        grid-template-columns: 1fr !important;
    }
    .ml250 {
        margin-left: 0px;
    }
    .main1 {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px !important;
        align-items: flex-start;
        padding: 20px;
        margin: 0px;
    }
    .ts_container {
        margin-top: 10px !important;        
        padding: 10px 0;
    }
    .left_cover {
        flex-direction: column;
    }
    .desc {
        margin-top: 0px;
        padding: 10px;
    }
    .ts_btn {
        border-radius: 100px !important;
        width: 90px !important;
        height: 37px;
        font-size: 16px;
        font-weight: 700;
        margin-left: auto;
    }
    .ts_logo {
        width: 60px;
        height: 60px;
    }
    .ts_vid_container {
        position: fixed;
        bottom: 0px;
        z-index: 1001;
        align-items: center;
        width: 100%;
        margin: 0px;
        padding: 5px 0px 5px 0px;
    }
    .ts_vid_container {
        display: grid;
        grid-template-columns: auto 1fr auto;
    }
    .ts_vid_container>div:nth-child(1) {
        padding-left: 10px;
    }
    .ts_vid_container>div:nth-child(2) {
        text-align: center;
    }
    .ts_vid_container>div:nth-child(3) {
        padding-right: 10px;
    }
    .ts_sin_vid {
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        display: block;
    }
}