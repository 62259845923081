.main>div:nth-child(1) {
    line-height: 40px;
}
.main>div:nth-child(2) {
    font-size: 14px;
    color: #E77996;
    cursor: pointer;
}
.main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.title {
    color: #333;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    /* margin-top: 20px;   */
}

@media only screen and (max-width: 600px) {    
    .main {
        padding-left: 10px;
        padding-right: 10px;
    }
}