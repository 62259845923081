.odd {
    background-color: #f7f7f7;
}

.even {
    background-color: #f7ebef;
}

.menu_parent {
    color: #fff;
    border-radius: 5px;
    min-width: 78px;
    padding: 5px 7px;
    margin-left: 20px;
}

.mp_orange {
    background: var(--theme_orange);
}
.mp_blue {
    background: var(--theme_blue);
}
.mp_original {
    background: var(--theme_oringinal);
}

.item {
    grid-template-columns: auto 1fr;
    height: 49px;
    padding: 0 5px;
    align-items: center;
}

.cater_label{
    /* font-size: 10px;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    color: white;
    background: var(--theme_oringinal);
    padding: 2px 4px;
    border-radius: 3px;
    margin-left: auto; */
    font-size: 10px;
    width: -moz-fit-content;
    width: fit-content;
    background: red !important;
    position: absolute;
    color: white;
    background: var(--theme_oringinal);
    top: -13px;
    right: -3px;
    padding: 2px 4px;
    border-radius: 3px;
}
.cater_item {
    color: #6a6a6a;
    text-decoration: none;
}

.cater_item:hover {
    cursor: pointer;
}

.ci_orange:hover {
    color: var(--theme_hover_orange);
}
.ci_blue:hover {
    color: var(--theme_hover_blue);
}
.ci_original:hover {
    color: var(--theme_hover_original);
}

.menu_parent {
    display: block;
}
.c_m_left {
    display: none;
}
.cili_wrapper {
    display: none;
}


@media only screen and (max-width: 600px) {
    .item>div:nth-child(2) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        /* gap: 5px; */
        column-gap: 5px;
        row-gap: 20px;
        font-weight: 500;
    }
    .c_m_left {
        padding-left: 10px;
        text-align: center;
        margin: 0 auto;;
    }
    .c_m_left > div {
        margin-top: 5px;
    }
    .item {
        height: 80px;
        font-size: 13px;
    }
    .menu_parent {
        display: none;
    }
    .c_m_left {
        display: block;
    }
    .h140 {
        height: 146px !important;
        grid-template-columns: 1fr !important;
        overflow: hidden;
    }
    .site_cili_img {
        object-fit: fill;
        overflow: hidden;
        height: 146px;
        width: 100%;
     }
     .cili_wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        min-height: 146px;
     }
}