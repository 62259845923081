.imgdiv {
    min-height: 70px;
    max-height: 70px;
}
.img {
    min-height: 70px;
    max-height: 70px;
}

@media only screen and (max-width: 600px) {
    .imgdiv {
        min-height: 30px;
        max-height: 30px;
    }
    .img {
        min-height: 30px;
        max-height: 30px;
    }
}