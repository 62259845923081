/* src/components/Modal.module.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  .modalContent {
    background: white;
    border-radius: 8px;
    /* padding: 20px; */
    width: 90%;
    max-width: 500px;
    position: relative;
  }
  
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .formgrid {
    grid-template-columns: 65% 35%;
  }

  .applygrid {
    grid-template-columns: 5% 95%;
  }

  .pinkbg {
    background-color: #FA6891;
  }