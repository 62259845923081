.con {
    top: 20px;
    width: 100%;
    height: 100%;
}

.rot {
    rotate: 180deg;
    float: left;
    position: relative;
}
.di_text {
    top: 6px;
    rotate: 180deg;
    left: -6p;
    position: absolute;
    left: 23px;
    color: white;
    cursor: pointer;
    font-weight: bold;
}
.header_logo {
    top: 47%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
    margin: auto;
}
.login_content {
    bottom: 101px;
    color: #fff;
    font-size: 20px;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
}
.mb_text {
    display: none;
}

@media only screen and (max-width: 750px) {
   .main_bg {
        object-fit: cover;
        object-position: top;
   }
   .login_content {
        display: none;
   }
   .mb_text {
        bottom: 58px;
        color: #fff;
        font-size: 20px;
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;
        display: block;
   }
   .header_logo {
    top: 31%;
   }
}