.vip_item {
    background: #f4dfc2;
    border-radius: 6px;
    color: #333;
    cursor: pointer;
    height: 63px;
    /* margin: 5px 0; */
    margin-bottom: 5px;
    padding: 5px 15px;
    grid-template-columns: 1fr auto;
}
.vip_item_event {
    background: #f4dfc2;
    border-radius: 0 0 6px 6px;
    color: #333;
    cursor: pointer;
    height: 63px;
    /* margin: 5px 0; */
    margin-bottom: 5px;
    padding: 5px 15px;
    grid-template-columns: 1fr auto;
}
.vip_item:hover {
    background-color: #f3ba69;
}
.top {
    width: 100%;

}
.vip_item_title {
    font-size: 16px;
    font-weight: 700;
    padding-top: 5px;
}
.activitymark{
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 4px;
    background-color: red;
    color: white;
    font-weight: 600;
}
.vip_old_price {
    color: #666;
    font-size: 12px;
    text-decoration: line-through;
}
.red_p {
    color: #e32647;
    font-size: 20px;
    font-weight: 700;
}
.buy_main {
    top: 20px;
    left: 0;
    right: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
    text-align: center;
}

.smallView {
    left: 0px;
    transform: none;
    width: 100% !important;
}
.importanttext{
    color: red;
    font-weight: 600;
    padding-bottom: 15px;
}
.mobileimportanttext{
    color: red;
    font-weight: 600;
    padding-bottom: 15px;
    font-size: 12px;
}
.postwrapper{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 10px;
}
.mobilepostwrapper{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-bottom: 10px;
}
.postwrapper>div, .mobilepostwrapper>div{
    grid-column: span 1 / span 1;
}
.n_1 {
    color: #c49470;
    font-size: 24px;
    font-weight: 700;
    line-height: 45px;
}
.n_2 {
    background: #eda64e;
    border-radius: 20px;
    color: #fff;
    margin: 0 auto;
    padding: 4px 0;
    text-align: center;
    width: 75%;
}
.n_3 {
    background: #FF647C;
    border-radius: 20px;
    color: #fff;
    margin: 0 auto;
    padding: 4px 0;
    text-align: center;
    width: 75%;
    margin-top: 10px;
    cursor: pointer;
}
.smallView > div > .buy_main {
    width: 100% !important;
}

@media only screen and (max-width: 600px) {
        .postwrapper, .mobilepostwrapper{
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        .postwrapper > div > img, .mobilepostwrapper > div > img{
            height: auto !important;
        }
        .n_1 {
            line-height: 25px;
        }
        .buy_main {
            width: 100%;
            transform: none;
            left: 0%;
            display: grid;
            gap: 17px;
        }
  }