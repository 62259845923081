.vipc {
    grid-template-columns: 50% 50%;
    row-gap: 10px;
    text-align: center;
    color: white;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
}
.pri {
    border-radius: 23px;
    font-size: 14px;
    line-height: 32px;
    width: 90%;
    background-color: #fff;
    color: #bf8f6a;
    text-align: center;
    margin: auto;
}
.wrapper {
    top: 109px;
    width: 100%;
    display: grid;
    gap: 15px;
}
.left {
    background: #ffb35b;
    color: #fff;
    border-radius: 20px;
    font-size: 12px;
    line-height: 30px;
    padding: 0 10px;
    text-decoration: none;  
    cursor: pointer;
}
.right {
    background: #f0e1d0;
    color: #7f532e;
    border-radius: 20px;
    font-size: 12px;
    line-height: 30px;
    padding: 0 10px;
    text-decoration: none;
    cursor: pointer;
}
.btm {
    width: 100%;
    display: flex;
    gap: 20px;
    bottom: -64px;
    justify-content: center;
}

.head_vip_m_c {
    position: absolute;
    z-index: 999;
    left: 50%;
    transform: translate(-42%, 75px);
    scale: 1.2;
}

.cbtn {
    bottom: 76px;
    font-size: 20px;
    left: 50%;
    transform: translate(-50%, 0px);
    width: 13px;
    top: auto !important;
    height: 22px;;
}