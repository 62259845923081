.arrow{
    rotate: 180deg;
    width: 15px;
    filter: opacity(0.5);
}
.direct_link{
    cursor: pointer;
    font-weight: 600;
    color: rgb(231, 121, 150);
    display: flex;
    justify-content: center;
    gap: 2px;
    font-size: 18px;
}
.sub_link{
    color: #e77996;
    font-weight: 500;
}
.header_title{
    display: flex;
    font-size: 21px;
    margin-bottom: 2px !important;
}
.ts {
    grid-template-columns: 1fr;
}
.ts_logo {
    width: 80px;
    height: 80px;
    border-radius: 50px;
}
.ts_title {
    color: #242424;
    font-size: 18px;
    font-weight: 700;
}
.line {
    height: 1px;
    width: 100%;
    background: #E7E7E7;
}

.ts_container {
    border: 1px solid #ECECEC;
    background-color: white;
    padding: 20px;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr 120px;
    gap: 30px;
}
.desc {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
}
.left_cover {
    /* margin-top: auto; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.ml250 {
    margin-left: 250px;
}
.main1 {
    display: none;
}
.ts_detail {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
}
.ts_de_img {
    border-radius: 50px;
}
.ts_sin_vid {
    display: none;
}

@media only screen and (max-width: 600px) {
    .home_direct_link{
        padding-left: 10px;
    }
    .home_icon{
        height: 15px;
        width: 15px;
    }
    .header_title{
        /* margin-left: 10px; */
    }
    .direct_link{
        font-size: 14px;
    }
    .main {
       display: none;
    }
    .ts {
        grid-template-columns: 1fr !important;
    }
    .ml250 {
        margin-left: 0px;
    }
    .main1 {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px !important;
        align-items: flex-start;
        padding: 20px;
        margin: 0px;
    }
    .ts_container {
        margin: 10px;
        gap: 20px;
    }
    .desc {
        height: 34px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 0px;
    }
    .ts_btn {
        border-radius: 100px !important;
        width: 90px !important;
        height: 37px;
        font-size: 16px;
        font-weight: 700;
        margin-left: auto;
    }
    .ts_logo {
        width: 60px;
        height: 60px;
    }
    .ts_vid_container {
        position: fixed;
        bottom: 0px;
        z-index: 1001;
        align-items: center;
        width: 100%;
        margin: 0px;
        padding: 5px 0px 5px 0px;
    }
    .ts_vid_container {
        display: grid;
        grid-template-columns: auto 1fr auto;
    }
    .ts_vid_container>div:nth-child(1) {
        padding-left: 10px;
    }
    .ts_vid_container>div:nth-child(2) {
        text-align: center;
    }
    .ts_vid_container>div:nth-child(3) {
        padding-right: 10px;
    }
    .ts_sin_vid {
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        display: block;
    }
}